var dataUser = JSON.parse(window.localStorage.getItem('userData'));

var userMenu = document.querySelector('.userMenu');

if(userMenu){

    // regulamento
    userMenu.querySelector('#regulamento').addEventListener('click', function(e){    
        e.preventDefault();
        
        var modal = document.querySelector("#regulamento-modal");    
        modal.style.display = "flex";    
        var closeModal =  document.querySelector('.default-modal-close');
    
        closeModal.onclick = function() {
            modal.style.display = "none";
        }
    
        window.onclick = function(event) {
            if (event.target == modal) {
                modal.style.display = "none";
            }
        }

    });

    //email
    userMenu.querySelector('#userEmail').innerHTML = dataUser.data.email;

    //nome usuário
    userMenu.querySelector('#nomeUser').innerHTML = dataUser.data.name;

    // regulamento
    userMenu.querySelector('#logout').addEventListener('click', function(e){
        e.preventDefault();
        localStorage.clear();
        setTimeout(() => {
            location.href = "/login";
        }, 100);
    });

}