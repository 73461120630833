//tabelas de jogos
var urlAws = window.location.origin === "https://bolao.sjcc.com.br" 
                ?
            "https://s3.amazonaws.com/sjcc.ne10.uol.com.br/quiz-sjcc/"  
                :
            "https://s3.amazonaws.com/sjcc.ne10.uol.com.br/quiz-sjcc/dev/";

var tabelaUsuario = document.querySelector('#table-usuario');
var tabelaRanking = document.querySelector('#table-ranking');

//data user
var dataUser = JSON.parse(window.localStorage.getItem('userData'));


//html
var htmlRaking = "";
var htmlPosicaoUsuario = "";

fetch(`${urlAws}ranking-bolao-ne10.json`)
.then((response) => response.json())
.then(function (data) {
    
    if(tabelaUsuario){

        document.querySelector('#participantes span').innerHTML = data.total_participante;

        //tabela usuário
        for(var i = 0; i < data.ranking.length; i++){
            if(data.ranking[i].usuario_id == dataUser.data.id){

                document.querySelector('#sua-posicao span').innerHTML = [i + 1] + 'º';

                htmlPosicaoUsuario += "<tr>";
                htmlPosicaoUsuario += " <td>" + data.ranking[i].pontos + "</td>";
                htmlPosicaoUsuario += " <td>" + data.ranking[i].palpites_realizados + "</td>";
                htmlPosicaoUsuario += " <td>" + data.ranking[i].palpites_corretos + "</td>";
                htmlPosicaoUsuario += " <td>" + data.ranking[i].palpites_aproximado + "</td>";
                htmlPosicaoUsuario += "</tr>";

            }
        }
        tabelaUsuario.querySelector('tbody').innerHTML = htmlPosicaoUsuario;
    }

    if(tabelaRanking){

        // tabela de ranking
        if(tabelaRanking.classList.contains('home')){
            for(var i = 0; i < 5; i++){
                if(data.ranking[i] != undefined){
                    htmlRaking += "<tr>";
                    htmlRaking += " <td>" + [i + 1] + "</td>";
                    htmlRaking += " <td>" + data.ranking[i].usuario + "</td>";
                    htmlRaking += " <td>" + data.ranking[i].palpites_realizados + "</td>";
                    htmlRaking += " <td>" + data.ranking[i].palpites_corretos + "</td>";
                    htmlRaking += " <td>" + data.ranking[i].palpites_aproximado + "</td>";
                    htmlRaking += " <td>" + data.ranking[i].pontos + "</td>";
                    htmlRaking += "</tr>";
                }
                
            }
        }else{
            for(var i = 0; i < 50; i++){
                if(data.ranking[i] != undefined){
                    htmlRaking += "<tr>";
                    htmlRaking += " <td>" + [i + 1] + "</td>";
                    htmlRaking += " <td>" + data.ranking[i].usuario + "</td>";
                    htmlRaking += " <td>" + data.ranking[i].palpites_realizados + "</td>";
                    htmlRaking += " <td>" + data.ranking[i].palpites_corretos + "</td>";
                    htmlRaking += " <td>" + data.ranking[i].palpites_aproximado + "</td>";
                    htmlRaking += " <td>" + data.ranking[i].pontos + "</td>";
                    htmlRaking += "</tr>";
                }
                
            }
        }
        

        tabelaRanking.querySelector('tbody').innerHTML = htmlRaking;
        
    } 
    
})
.catch(function (error) {
    console.log('Error:', error);
});



