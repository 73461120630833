$(window).on("load", function () {

    //quebra o cache do background para animação
    if (window.matchMedia("(min-width: 540px)").matches) {
      $('.header-content').css('background-image', 'url("https://jc.ne10.uol.com.br/dist/assets/images/icons-classificacao/bolas.svg?nocache=' + Math.random() + '")');
    }
 
    conteudoCampeonato = $('.conteudo-campeonato');
 
    //loading
    var divLoading = '<div class="divLoading d-flex justify-content-center align-items-center" style="height:200px; width:100%;"><img style="height:50px" src="https://jc.ne10.uol.com.br/dist/assets/images/icons-classificacao/bola-loading-copa-nordeste.svg"/></div>';
 
 
    /* -------------------------------------
     funcao carrega dados das fases
     ------------------------------------- */
 
    function jsonFeedFases(jsonFases) {
 
       //inicia o html
       var html = "";
 
       //insere loading
       $(conteudoCampeonato).html(divLoading);
 
       // dados do campeonato  
       $.getJSON(jsonFases, function (dataFases) {
 
          //troca titulo fase
          $('.nav-fases h4').css('opacity', 0);
 
          setTimeout(() => {
             $('.nav-fases h4').html(dataFases.nome);
             $('.nav-fases h4').css('opacity', 1);
          }, 100);
 
          //dados da fase proxima/anterior 
          proximaFase = dataFases.proxima_fase;
 
          faseAnterior = dataFases.fase_anterior;
 
          if (faseAnterior == null) {
             $('.prev-fase').addClass('disabled');
          } else {
             $('.prev-fase').removeClass('disabled');
          }
 
          if (proximaFase == null) {
             $('.prox-fase').addClass('disabled');
          } else {
             $('.prox-fase').removeClass('disabled');
          }
 
          //adiciona o id no container pai
          $('.container-jogos').attr('id', dataFases.tipo);
 
          if (dataFases.tipo == "grupo-contra-grupo") {
 
             setTimeout(() => {
 
             //mostra o menu de fases
             $('.nav-fases').removeClass('hide');
 
             //limpa o html
             $(conteudoCampeonato).html('');
 
             countGrupo = 0;

             adicionaJogos(jsonFases);
 
             // html grupos
             $.each(dataFases.grupos, function (keyGrupo, grupo) {

 
                if (grupo.tabela.length > 0) {
 
                   html += '<div class="col-md-12">';
                  //  if (keyGrupo == "grupo-3") {
                  //     html += '<div id="banner-300x250-3" class="ads ads-retangular text-center"></div>';
                  //     window.uolads.push({
                  //        id: "banner-300x250-3"
                  //     });
                  //  }
                  //  if (keyGrupo == "grupo-5") {
                  //     html += '<div id="banner-300x250-4" class="ads ads-retangular text-center"></div>';
                  //     window.uolads.push({
                  //        id: "banner-300x250-4"
                  //     });
                  //  }
                  //  if (keyGrupo == "grupo-7") {
                  //     html += '<div id="banner-300x250-5" class="ads ads-retangular text-center"></div>';
                  //     window.uolads.push({
                  //        id: "banner-300x250-5"
                  //     });
                  //  }
                   html += '      <div>';
                   html += '          <div class="table-content table-responsive">';
                   html += '              <table class="table">';
                   html += '                 <thead>';
                   html += '                    <tr>';
                   html += '                        <th class="title-col pl-2" scope="col">' + grupo.nome + '</th>';
                   html += '                       <th scope="col">P</th>';
                   html += '                       <th scope="col">J</th>';
                   html += '                       <th scope="col">V</th>';
                   html += '                       <th scope="col">E</th>';
                   html += '                       <th scope="col">D</th>';
                   html += '                       <th scope="col">GP</th>';
                   html += '                       <th scope="col">GC</th>';
                   html += '                       <th scope="col">SG</th>';
                   html += '                       <th scope="col">%</th>';
                   html += '                    </tr>';
                   html += '                 </thead>';
                   html += '                 <tbody class="lista-table">';
                   $.each(grupo.tabela, function (key, registro) {
                     html += '                  <tr>';
                     html += '                     <th class="primary-col" scope="row">';
                     html += '                        <span class="mr-2">' + registro.posicao + '.</span>';
                     html += '                        <strong>' + registro.time.sigla + '</strong>';
                     html += '                     </th>';
                     html += '                     <td>' + registro.pontos + '</td>';
                     html += '                     <td>' + registro.jogos + '</td>';
                     html += '                     <td>' + registro.vitorias + '</td>';
                     html += '                     <td>' + registro.empates + '</td>';
                     html += '                     <td>' + registro.derrotas + '</td>';
                     html += '                     <td>' + registro.gols_pro + '</td>';
                     html += '                     <td>' + registro.gols_contra + '</td>';
                     html += '                     <td>' + registro.saldo_gols + '</td>';
                     html += '                     <td>' + registro.aproveitamento + '</td>';
                     html += '                 </tr> ';
                   });
                   html += '                 </tbody>';
                   html += '              </table>';
                   html += '           </div>';
                   html += '         </div>';
                   html += '      </div>';
                   
                   if (countGrupo == (Object.keys(dataFases.grupos).length - 1)) {
                      html += '   <div class="info-table">';
                      html += '       <div class="content-info-table"><p>Classificados</p></div>';
                      html += '      <ul>';
                      html += '          <li><strong>P</strong>pontos</li>';
                      html += '          <li><strong>J</strong>jogos</li>';
                      html += '          <li><strong>V</strong>vitórias</li>';
                      html += '          <li><strong>E</strong>empates</li>';
                      html += '          <li><strong>D</strong>derrotas</li>';
                      html += '          <li><strong>GP</strong>gols pró</li>';
                      html += '          <li><strong>GC</strong>gols contra</li>';
                      html += '          <li><strong>SG</strong>saldo de gols</li>';
                      html += '          <li><strong>%</strong>aproveitamento</li>';
                      html += '       </ul>';
                      html += '   </div>';
                   }

                } else {
                   if (countGrupo == 0) {
                      html += '<div class="alert-info-campeonado">Ainda não temos informações sobre esta fase.</div>';
                   }
                }
 
                $(conteudoCampeonato).html(html);
               
                countGrupo++;
                
             });
             }, 300);
 
             // fase mata mata
          } else if (dataFases.tipo == "mata-mata") {
 
             setTimeout(() => {
                
                   //mostra menu fases
                   $('.nav-fases').removeClass('hide');
 
                   //verifica ida e volta
                   if (dataFases.ida_e_volta == true) {
                      html += '<div class="col-md-12">';
                      html += '   <ul class="container-mata-mata">';
                      count = 0;
                      $.each(dataFases.chaves, function (key, chave) {
                         count++;
                         if (Object.keys(chave.partida_ida).length > 0 && Object.keys(chave.partida_volta).length > 0) {
                            html += '      <li>';
                            html += '         <div class="ida">';
                            html += '            <span class="ida-volta">IDA</span>';
                            html += '            <div class="info-jogo"><span>' + chave.partida_ida.data_realizacao + '</span><span> ' + chave.partida_ida.estadio.nome_popular + ' </span><span>' + chave.partida_ida.hora_realizacao + '</span></div>';
                            html += '            <div class="container-placar-jogos">';
                            html += '               <div>';
                            html += '                  <span>' + chave.partida_ida.time_mandante.nome_popular + '</span>';
                            if (chave.partida_ida.placar_mandante != null) {
                               html += '               <span class="placar-jogo">' + chave.partida_ida.placar_mandante + '</span>';
                            }
                            html += '               </div>';
                            html += '               <span class="versos">x</span>';
                            html += '               <div>';
                            if (chave.partida_ida.placar_visitante != null) {
                               html += '                  <span class="placar-jogo">' + chave.partida_ida.placar_visitante + '</span>';
                            }
                            html += '                  <span>' + chave.partida_ida.time_visitante.nome_popular + '</span>';
                            html += '               </div>';
                            html += '            </div>';
                            html += '         </div>';
                            html += '         <div class="content-chave">';
                            html += '            <strong>' + chave.nome + '</strong>';
                            html += '         </div>';
                            html += '         <div class="volta">';
                            html += '            <span class="ida-volta">VOLTA</span>';
                            html += '            <div class="info-jogo"><span>' + chave.partida_volta.data_realizacao + '</span><span> ' + chave.partida_volta.estadio.nome_popular + ' </span><span>' + chave.partida_volta.hora_realizacao + '</span></div>';
                            html += '            <div class="container-placar-jogos">';
                            html += '               <div>';
                            html += '                  <span>' + chave.partida_volta.time_mandante.nome_popular + '</span>';
                            if (chave.partida_volta.placar_mandante != null) {
                               html += '                  <span class="placar-jogo">' + chave.partida_volta.placar_mandante + '</span>';
                            }
                            html += '               </div>';
                            html += '               <span class="versos">x</span>';
                            html += '               <div>';
                            if (chave.partida_volta.placar_visitante != null) {
                               html += '                  <span class="placar-jogo">' + chave.partida_volta.placar_visitante + '</span>';
                            }
                            html += '                  <span>' + chave.partida_volta.time_visitante.nome_popular + '</span>';
                            html += '               </div>';
                            html += '            </div>';
                            html += '         </div>';
                            html += '       </li>';
                         } else {
                            if (count == 1) {
                               html += '<div class="alert-info-campeonado">Ainda não temos informações sobre esta fase.</div>';
                            }
                         }
                      });
                      html += '   </ul>';
                      html += '</div>';
                   } else {
                      html += '<div class="col-md-12">';
                      html += '   <ul class="container-mata-mata apenas-num">';
                      $.each(dataFases.chaves, function (key, chave) {
                         html += '      <li>';
                         html += '         <div class="ida">';
                         html += '            <div class="info-jogo"><span>' + chave.partida_ida.data_realizacao + '</span><span> ' + chave.partida_ida.estadio.nome_popular + ' </span><span>' + chave.partida_ida.hora_realizacao + '</span></div>';
                         html += '            <div class="container-placar-jogos">';
                         html += '               <div>';
                         html += '               <span>' + chave.partida_ida.time_mandante.nome_popular + '</span>';
                         if (chave.partida_ida.placar_mandante != null) {
                            html += '               <span class="placar-jogo">' + chave.partida_ida.placar_mandante + '</span>';
                         }
                         html += '               </div>';
                         html += '               <span class="versos">x</span>';
                         html += '               <div>';
                         if (chave.partida_ida.placar_visitante != null) {
                            html += '               <span class="placar-jogo">' + chave.partida_ida.placar_visitante + '</span>';
                         }
                         html += '               <span>' + chave.partida_ida.time_visitante.nome_popular + '</span>';
                         html += '               </div>';
                         html += '            </div>';
                         html += '         </div>';
                         html += '       </li>';
                      });
                      html += '   </ul>';
                      html += '</div>';
 
                   }
                   $(conteudoCampeonato).html(html);
             }, 300);
 
          }

       }).fail(function(jqXHR,textStatus,errorThrown){
         
         $(conteudoCampeonato).html('<div class="alert-info-campeonado">Ainda não temos informações sobre esta fase.</div>');

       });

       function adicionaJogos(json){

            $.getJSON(jsonFases, function (dataJogos) {

               partidas = dataJogos.partidas;

               //html de rodadas
               htmlRodada = "";
 
               htmlRodada += '<div class="container-rodadas">';
               htmlRodada += '   <div class="box-rodadas">';
               htmlRodada += '         <div class="nav-rodadas">';
               htmlRodada += '            <button class="prev-rodada"><i class="fas fa-chevron-left"></i></button>';
               htmlRodada += '             <h5><span class="rodadaNumero"></span>' + dataJogos.campeonato.rodada_atual.nome + '</h5>';
               if(dataJogos.campeonato.rodada_atual == null){
                  htmlRodada += '          <button class="prox-rodada disabled"><i class="fas fa-chevron-right"></i></button>';
               }else{
                  htmlRodada += '          <button class="prox-rodada"><i class="fas fa-chevron-right"></i></button>';
               }
               htmlRodada += '         </div>';
               htmlRodada += '      <ul class="listRodadas">';
 
               countRodadas = 0;
 
               $.each(partidas, function (key, registros) {
 
                  countRodadas++;
 
                  //se tiver rodada atual
                  if (dataJogos.campeonato.rodada_atual != null) {   
 
                     var rodadaAtual = dataJogos.campeonato.rodada_atual.slug;
 
                     if (rodadaAtual == key) {
 
                        var varRodada = rodadaAtual.split('-')[0];
                        tituloRodada = varRodada.replace('a', '');
                        tituloRodada = parseInt(tituloRodada);
 
                        $.each(registros, function (key, registro) {
                           htmlRodadas(registro);
                        });                     
 
                        if (countRodadas == Object.keys(partidas).length) {
                           if (key == rodadaAtual) {
                              $('.prox-rodada').addClass('disabled');
                           }
                        }
                     }
 
                   } else {
 
                      //pega a ultima rodada
                      if (countRodadas === (Object.keys(partidas).length)) {
 
                         var varRodada = key.split('-')[0];
                         tituloRodada = varRodada.replace('a', '');
                         tituloRodada = parseInt(tituloRodada);
 
                         $.each(registros, function (key, registro) {
                            htmlRodadas(registro);
                         });
 
                      }
 
                   }
 
               });
 
               htmlRodada += '         </ul>';
               htmlRodada += '      </div>';
               htmlRodada += '   </div>';

               document.querySelector('#conteudoRodadas').innerHTML = htmlRodada;

            });

            
       }

    }
 
    /* -------------------------------------
     funcao monta o html das rodadas
     ------------------------------------- */
    function htmlRodadas(registro) {
       htmlRodada += '<li>';
       htmlRodada += ' <a href="' + registro._link + '">';
       htmlRodada += '  <p>';
       htmlRodada += '     <strong>';
       if (registro.data_realizacao != null) {
          htmlRodada += registro.data_realizacao;
       } else {
          htmlRodada += '';
       }
       htmlRodada += '     </strong>';
       if (registro.estadio != null) {
          htmlRodada += registro.estadio.nome_popular;
       } else {
          htmlRodada += '';
       }
       htmlRodada += '     <strong>';
       if (registro.hora_realizacao != null) {
          htmlRodada += registro.hora_realizacao;
       } else {
          htmlRodada += '';
       }
       htmlRodada += '     </strong>';
       htmlRodada += '   </p>';
       htmlRodada += '   <div class="d-flex align-items-center content-placar">';
       htmlRodada += '   <div>';
       htmlRodada += '     <h5 >';
       htmlRodada += registro.time_mandante.nome_popular;
       htmlRodada += '     </h5>';
       htmlRodada += '     <span>';
       if (registro.placar_mandante != null) {
          htmlRodada += registro.placar_mandante;
       } else {
          htmlRodada += '';
       }
       htmlRodada += '     </span>';
       htmlRodada += '   </div>';
       htmlRodada += '     <span class="mx-2 versos"> x </span>';
       htmlRodada += '   <div>';
       htmlRodada += '     <span>';
       if (registro.placar_visitante != null) {
          htmlRodada += registro.placar_visitante;
       } else {
          htmlRodada += '';
       }
       htmlRodada += '     </span>';
       htmlRodada += '     <h5>';
       htmlRodada += registro.time_visitante.nome_popular;
       htmlRodada += '     </h5>';
       htmlRodada += '   </div>';
       htmlRodada += '   </div>';
       htmlRodada += ' </a>';
       htmlRodada += '</li>';
    }
 
    /* -------------------------------------
     Botão proximo/anterior rodadas
     ------------------------------------- */
 
    // prox btn
    $(document).on('click', '.prox-rodada', function () {
 
       var elementClick = $(this);
 
       $(elementClick).addClass('disabled');
 
       var contSelector = $(elementClick).parents('.box-rodadas').find('.listRodadas');
 
       $(contSelector).html(divLoading);
 
       setTimeout(() => {
 
          if (tituloRodada < Object.keys(partidas).length) {
             tituloRodada++;
             $('.prev-rodada').removeClass('disabled');
          }
 
          $(elementClick).parents('.box-rodadas').find('h5').html(tituloRodada + 'ª Rodada');
 
          var rodadaAtual = tituloRodada + 'a-rodada';
 
          $(contSelector).html('');
 
          htmlRodada = "";
 
          $.each(partidas, function (key, registros) {
 
             if (rodadaAtual == key) {
 
                $.each(registros, function (key, registro) {
                   htmlRodadas(registro);
                });
 
                $(contSelector).append(htmlRodada);
 
                if (tituloRodada != Object.keys(partidas).length) {
                   $(elementClick).removeClass('disabled');
                }
             }
 
          });
       }, '300');
 
    });
 
 
    // prev btn
    $(document).on('click', '.prev-rodada', function () {
 
       var elementClick = $(this);
 
       $(elementClick).addClass('disabled');
 
       var contSelector = $(elementClick).parents('.box-rodadas').find('.listRodadas');
 
       $(contSelector).html(divLoading);
 
       setTimeout(() => {
 
          if (tituloRodada > 1) {
 
             tituloRodada--;
 
             $('.prox-rodada').removeClass('disabled');
 
          }
 
          $(elementClick).parents('.box-rodadas').find('h5').html(tituloRodada + 'ª Rodada');
 
          var rodadaAtual = tituloRodada + 'a-rodada';
 
          htmlRodada = "";
 
          $(contSelector).html('');
 
          $.each(partidas, function (key, registros) {
 
             if (rodadaAtual == key) {
                if (tituloRodada != 1) {
                   $(elementClick).removeClass('disabled');
                }
 
                $.each(registros, function (key, registro) {
                   htmlRodadas(registro);
                });
 
                $(contSelector).append(htmlRodada);
             }
 
          });
       }, '300');
 
    });
 
    /* -------------------------------------
     Botão proximo/anterior rodadas de grupo
     ------------------------------------- */
 
    //btn prev
    $(document).on('click', '.prev-rodada-group', function () {
 
       var elementClick = $(this);
 
       $(elementClick).addClass('disabled');
 
       $(elementClick).parents('.box-rodadas').find('.listRodadas').html(divLoading);
 
       numeroRodada = $(elementClick).parents('.box-rodadas').find('h5').html().split('ª')[0];
 
       numeroRodada = parseInt(numeroRodada);
 
       numeroRodada = numeroRodada;
 
       if (numeroRodada > 1) {
          numeroRodada--
          $(elementClick).parents('.box-rodadas').find('h5').html(numeroRodada + 'ª rodada');
       }
 
       rodadaNome = numeroRodada + "a-rodada";
       varGroupId = $(elementClick).parents('.box-rodadas').attr('id');
 
       html = "";
 
       setTimeout(() => {
          $.getJSON(jsonFases, function (dataFases) {
             $.each(dataFases.grupos, function (key, grupo) {
                if (grupo.slug == varGroupId) {
                   var selectGroup = grupo.partidas;
                   $.each(selectGroup, function (key, rodadas) {
                      if (rodadaNome == key) {
                         selectRodada = rodadas;
                         $.each(selectRodada, function (key, rodada) {
                            htmlRodada = "";
                            htmlRodadas(rodada);
                            html += htmlRodada;
                         });
                      }
                      $(elementClick).parents('.box-rodadas').find('.listRodadas').html(html);
                   });
                }
             });
          });
       }, '300');
 
       if (numeroRodada >= 2) {
          setTimeout(() => {
             $(elementClick).removeClass('disabled');
          }, '500');
       }
 
       $(elementClick).parents('.box-rodadas').find('.prox-rodada-group').removeClass('disabled');
 
    });
 
    //btn prox
    $(document).on('click', '.prox-rodada-group', function () {
 
       var elementClick = $(this);
 
       $(elementClick).addClass('disabled');
 
       $(elementClick).parents('.box-rodadas').find('.listRodadas').html(divLoading);
 
       numeroRodada = $(elementClick).parents('.box-rodadas').find('h5').html().split('ª')[0];
 
       numeroRodada = parseInt(numeroRodada);
 
       numeroRodada = numeroRodada;
 
       rodadaNome = numeroRodada + "a-rodada";
       varGroupId = $(elementClick).parents('.box-rodadas').attr('id');
 
       html = "";
 
       setTimeout(() => {
          $.getJSON(jsonFases, function (dataFases) {
             $.each(dataFases.grupos, function (key, grupo) {
                if (grupo.slug == varGroupId) {
                   var selectGroup = grupo.partidas;
                   var lenPartidas = parseInt(Object.keys(selectGroup).length);
                   if (numeroRodada < lenPartidas) {
                      numeroRodada++
                      $(elementClick).parents('.box-rodadas').find('h5').html(numeroRodada + 'ª rodada');
                   }
                   if (numeroRodada < lenPartidas) {
                      $(elementClick).removeClass('disabled');
                   }
                   $.each(selectGroup, function (key, rodadas) {
                      if (rodadaNome == key) {
                         selectRodada = rodadas;
                         $.each(selectRodada, function (key, rodada) {
                            htmlRodada = "";
                            htmlRodadas(rodada);
                            html += htmlRodada;
                         });
                      }
                      $(elementClick).parents('.box-rodadas').find('.listRodadas').html(html);
                   });
                }
             });
          });
       }, '300');
 
       $(elementClick).parents('.box-rodadas').find('.prev-rodada-group').removeClass('disabled');
 
    });
 
    /* -------------------------------------
     Botão proximo/anterior fases
     ------------------------------------- */
 
    //prox fase
    $(document).on('click', '.prox-fase', function () {
 
       $(conteudoCampeonato).html(divLoading);
 
       var jsonProxFase = 'https://s3.amazonaws.com/sjcc.ne10.uol.com.br/api-automacao-de-conteudo/json/futebol/fases/copa-do-nordeste/' + proximaFase.fase_id + '.json';
 
       setTimeout(() => {
          jsonFeedFases(jsonProxFase);
       }, '300');
 
 
    });
 
    //prev fase
    $(document).on('click', '.prev-fase', function () {
 
       $(conteudoCampeonato).html(divLoading);
 
       var jsonPrevFase = 'https://s3.amazonaws.com/sjcc.ne10.uol.com.br/api-automacao-de-conteudo/json/futebol/fases/copa-do-nordeste/' + faseAnterior.fase_id + '.json';
 
       setTimeout(() => {
          jsonFeedFases(jsonPrevFase);
       }, '300');
    });
 
    /* -------------------------------------
     Init function
     ------------------------------------- */
     jsonFeedFases('https://s3.amazonaws.com/sjcc.ne10.uol.com.br/api-automacao-de-conteudo/json/futebol/fases/copa-do-nordeste/245.json');
 
 });