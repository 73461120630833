var boxesRodadas =  document.querySelectorAll('.containerRodadas');

if(boxesRodadas.length > 0){

    boxesRodadas.forEach(function(boxRodada){

        //boxes rodadas
        var boxes = boxRodada.querySelectorAll('.rodada');

        //roda atual
        var rodadaAtual = document.querySelector('#jogos').getAttribute('data-rodada');

        // mostrar ativo
        boxes.forEach(function(box){
            if(box.getAttribute('id') == rodadaAtual){
              box.classList.add('boxAtivo');
            }
        });


    });


    //next prev / titulo rodada
    var prev = document.querySelector('.prevRodada');
    var next = document.querySelector('.nextRodada');
    var titleRodada = document.querySelector('.titleRodada');

    //desabilita botoes
    boxAtivo = document.querySelector('.boxAtivo');
    if(boxAtivo){
        if(boxAtivo.nextElementSibling != null){
          next.disabled = false;
        }
        if(boxAtivo.previousElementSibling != null){
          prev.disabled = false;
        }
    }
    

    var containerWrap = document.querySelector('.boxesRodadas');

    if(containerWrap){
      //btn prev
      prev.addEventListener('click', function(e){
        e.preventDefault();

        boxesRodadas.forEach(function(boxRodada){        
        
          //esconde e mostra proximo elemento
          var activeBox = boxRodada.querySelector('.boxAtivo');
          
          if(activeBox.previousElementSibling){  

            containerWrap.classList.add("loading");           
              setTimeout(() => {
                  activeBox.classList.remove('boxAtivo'); 
                  activeBox.previousElementSibling.classList.add('boxAtivo');
                  containerWrap.classList.remove("loading");
              }, 300);
                          
          }
          
          if(!activeBox.previousElementSibling.previousElementSibling){
              document.querySelector('.nextRodada').disabled = false;
              prev.disabled = true;
          }

          if(activeBox.nextElementSibling == null){
            document.querySelector('.nextRodada').disabled = false;
          }

          //atualiza titulo rodada
          titleRodada.innerHTML = activeBox.previousElementSibling.getAttribute('data-rodada-name');

          });

      });

    // btn next
    next.addEventListener('click', function(e){
      e.preventDefault();

      boxesRodadas.forEach(function(boxRodada){

      //esconde e mostra proximo elemento
        var activeBox = boxRodada.querySelector('.boxAtivo');

        if(activeBox.nextElementSibling){
            containerWrap.classList.add("loading"); 
            setTimeout(() => {
              activeBox.classList.remove('boxAtivo');
              activeBox.nextElementSibling.classList.add('boxAtivo');
              containerWrap.classList.remove("loading");
            }, 300);
        }

        if(!activeBox.nextElementSibling.nextElementSibling){
            next.disabled = true;
        }

        if(activeBox.previousElementSibling == null){
          document.querySelector('.prevRodada').disabled = false;
        }

        //atualiza titulo rodada
        titleRodada.innerHTML = activeBox.nextElementSibling.getAttribute('data-rodada-name');

      });

    });
    }
    
   

    
}

