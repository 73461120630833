
var tabs = document.querySelector('#tabs');

if(tabs){

  var tabNav = tabs.querySelectorAll('nav a');
  var containerWrap = document.querySelector('#tabs');

  tabNav.forEach(function(tab){

    if(tab.classList.contains('ativo')){
        document.querySelector(tab.getAttribute('href')).classList.add('ativo');
    }

    tab.addEventListener('click', function(e){
        e.preventDefault();

        containerWrap.classList.add('loading');

        setTimeout(() => {

            containerWrap.classList.remove('loading');

            tabNav.forEach(function(tab){
                tab.classList.remove('ativo');
            });
    
            tab.classList.add('ativo');
    
            document.querySelectorAll('.tabContent').forEach(function(tabContent){
                if(tab.getAttribute('href').replace('#', '') != tabContent.getAttribute('id')){
                    tabContent.classList.remove('ativo');
                }else{
                    tabContent.classList.add('ativo');
                }
            });
            
        }, 300);

        

    });

  });

}