


//form jogos
var formJogos = document.querySelector("#formJogos");

if (formJogos) {

    // verifica se o usuario esta logado e direciona
    if (!JSON.parse(window.localStorage.getItem("userData"))) {
        location.href = "/login";
    }

    //loading

    containerWrap = formJogos.querySelector(".boxesRodadas");

    if(containerWrap){
        containerWrap.classList.add('loading');
    }
    

    //data user
    var dataUser = JSON.parse(window.localStorage.getItem("userData"));

    // carrega os palpites
    fetch(`${window.location.origin}/api/meus-palpites`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text-plain, */*",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: "Bearer" + dataUser.token,
        },
    })
        .then((response) => response.json())
        .then(function (data) {

            // remove loading
            if(containerWrap){
                containerWrap.classList.remove('loading');
            }
           

            //jogos
            document.querySelectorAll(".boxesRodadas ul li").forEach(function (jogo) {
                    
                    //palpites
                    data.data.forEach(function (palpite) {

                        //adiciona os valores palpitados
                        if (jogo.getAttribute("data-partidaid") ==  palpite.partida_id) {

                            //adiciona os valores nos inputs
                            if (jogo.querySelector('[name="time_mandante"]')) {
                                jogo.querySelector('[name="time_mandante"]').value = palpite.palpite_mandante;
                                jogo.querySelector('[name="time_visitante"]').value = palpite.palpite_visitante;
                                jogo.querySelector('[name="time_mandante"]').setAttribute("data-placar", palpite.palpite_mandante);
                                jogo.querySelector('[name="time_visitante"]').setAttribute("data-placar", palpite.palpite_visitante);

                            }

                            //adiciona o box de palpites
                            if(jogo.getAttribute('data-statusjogo') == 'finalizado' || jogo.getAttribute('data-statusjogo') == 'andamento'){
                                
                                htmlPalpite = "";
    
                                if(jogo.getAttribute('data-statusjogo') == 'andamento'){
                                    htmlPalpite += '<div class="encerrado andamento">';
                                }else{
                                    htmlPalpite += '<div class="encerrado">';
                                }
                                htmlPalpite += '    <div class="palpite">';
                                htmlPalpite += '        <span>SEU PALPITE</span>';
                                htmlPalpite += '        <div class="placar">';
                                htmlPalpite += '            <span class="time1">' + jogo.querySelector('.time1').querySelector('.nome').innerHTML + '</span>';
                                htmlPalpite += '            <strong class="ponto1">' + palpite.palpite_mandante + '</strong>';
                                htmlPalpite += '            <strong class="versos">x</strong>';
                                htmlPalpite += '            <strong class="ponto2">' + palpite.palpite_visitante +'</strong>';
                                htmlPalpite += '            <span class="time2">' + jogo.querySelector('.time2').querySelector('.nome').innerHTML +' </span>';
                                htmlPalpite += '        </div>';
                                if(jogo.getAttribute('data-statusjogo') == 'finalizado'){
                                    htmlPalpite += '        <strong class="total">+<span>' + palpite.pontos + '</span> PONTO</strong>';
                                }
                                htmlPalpite += '    </div>';
                                htmlPalpite += '</div>';
    
                                jogo.insertAdjacentHTML(
                                    "beforeEnd",
                                     htmlPalpite
                                );
    
                                
                            }else{
                                jogo.insertAdjacentHTML(
                                    "beforeEnd",
                                    "<span class='palpiteConfirmado'><i class='fas fa-check-circle'></i> PALPITE CONFIRMADO</span>"
                                );
                            }

                        }
                    });
                });
        })
        .catch(function (error) {
            console.log("Error:", error);
        });

    //mascara inputs numero e adiciona ID do jogador
    formJogos.querySelectorAll("input").forEach(function (input) {
        if (input.getAttribute("name") == "user_id") {
            input.value = JSON.parse(window.localStorage.getItem("userData")).data.id;
        } else {
            input.addEventListener("keyup", (e) => {
                input.value = input.value.replace(/\D/g, "");
            });
        }
    });

    //verifica se o jogo está em andamento
    document.querySelectorAll(".boxesRodadas ul li").forEach(function (jogo) {

        // jogo em andamento
        var currentdate = new Date();
        
        if(Date.parse(jogo.getAttribute('data-horapartida')) < Date.parse(currentdate.getFullYear()+'-'+(currentdate.getMonth() + 1)+'-'+currentdate.getDate()+' '+currentdate.getHours()+':'+currentdate.getMinutes()+':'+currentdate.getSeconds())){
            if(jogo.getAttribute('data-statusjogo') != 'finalizado'){
                if(jogo.querySelectorAll('input').length > 0){
                    jogo.querySelectorAll('input').forEach(function(input){
                        input.disabled = true;
                    });
                }
                jogo.querySelector('#jogoemandamento').style.display = "block";
            }
        }


    });


    //efeito focus // jogo em andamento
    formJogos.querySelectorAll(".jogo input").forEach(function (input) {
        
        //adiciona efeito de focus
        input.addEventListener("focus", function(){
            this.closest('li').classList.add('focus');
        });

        input.addEventListener("focusout", function(){
            this.closest('li').classList.remove('focus');
        });
      
        input.addEventListener('keyup', function(){

            //verifica se há inputs alterados
            if(this.value != this.getAttribute('data-placar')){
                this.classList.add('alterado');
                document.querySelector('.botaoFixo button').classList.remove('disable');
            }else{
                this.classList.remove('alterado');
                if(document.querySelectorAll('.alterado').length < 1){
                    document.querySelector('.botaoFixo button').classList.add('disable');
                }
            }

            // jogo em andamento
            var currentdate = new Date();
        
            if(Date.parse(input.closest('li').getAttribute('data-horapartida')) < Date.parse(currentdate.getFullYear()+'-'+(currentdate.getMonth() + 1)+'-'+currentdate.getDate()+' '+currentdate.getHours()+':'+currentdate.getMinutes()+':'+currentdate.getSeconds())){
                
                input.closest('li').querySelectorAll('input').forEach(function(inputJogo){
                    inputJogo.disabled = true;
                });

                if(input.closest('li').querySelector('.palpiteConfirmado')){
                    input.closest('li').querySelector('.palpiteConfirmado').remove();
                }

                input.closest('li').insertAdjacentHTML(
                    "beforeEnd",
                    "<span class='erroPalpite'><i class='far fa-times-circle'></i> Você não pode palpitar, jogo em andamento.</span>"
                );

                setTimeout(() => {
                    location.reload();
                }, 300);
                
            }


         });

    });
    
    // dados
    var jogosData = [];

    //submit form jogos
    formJogos.addEventListener("submit", function (e) {
        e.preventDefault();

        //verifica os valores dos inputs e adiciona os dados
        formJogos.querySelectorAll(".jogo").forEach(function (jogo) {

            var partida_id = jogo.querySelector('[name="partida_id"]'),
            user_id = jogo.querySelector('[name="user_id"]'),
            palpite_mandante = jogo.querySelector('[name="time_mandante"]'),
            palpite_visitante = jogo.querySelector('[name="time_visitante"]');

            var erroPalpite = jogo.parentNode.querySelector('.erroPalpite');
            
            if(palpite_mandante){
                if (palpite_mandante.value != "" && palpite_visitante.value != "") {
                    if(erroPalpite){
                        erroPalpite.remove();
                    }
                    var arrJogos = {
                        usuario_id: user_id.value,
                        partida_id: partida_id.value,
                        palpite_mandante: palpite_mandante.value,
                        palpite_visitante: palpite_visitante.value,
                    };
                    jogosData.push(arrJogos);
                }else{
                    if(palpite_mandante.value != "" || palpite_visitante.value != ""){
                    if(!erroPalpite){
                        jogo.parentNode.insertAdjacentHTML(
                        "beforeEnd",
                        "<span class='erroPalpite'><i class='far fa-times-circle'></i> Este palpite não foi enviado por estar incompleto.</span>"
                        );
                    }
                    }
                }
            } 
        });

        // envia os dados
        if(formJogos.querySelectorAll('.alterado').length > 0 && formJogos.querySelectorAll('.erroPalpite').length == 0){

            //loading
            var containerAlert = formJogos.querySelector(".alertMsn");
            var containerWrapTabs = document.querySelector("#tabs");
            containerWrapTabs.classList.add('loading');

            //envia os palpites

            fetch(`${window.location.origin}/api/palpite`, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json, text-plain, */*",
                    "X-Requested-With": "XMLHttpRequest",
                    Authorization: "Bearer" + dataUser.token,
                },
                method: "post",
                credentials: "same-origin",
                body: JSON.stringify(jogosData),
            })
            .then((data) => {
                containerWrapTabs.classList.remove('loading');
                containerAlert.className = "alertMsn sucesso"
                containerAlert.innerHTML = "Palpites confirmados";
                setTimeout(() => {
                    containerAlert.className = "alertMsn";
                    containerAlert.innerHTML = "";
                }, 2000);
            })
            .catch(function (error) {
                console.log(error);
            });
        }else{
            containerAlert.className = "alertMsn erro";
            containerAlert.innerHTML = "Palpites não enviados";
        }

        
    });
}
