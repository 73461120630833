//form login

var formLogin = document.querySelector('#formlogin');

if(formLogin || document.querySelector('#home')){
  if(JSON.parse(window.localStorage.getItem('userData'))){
    location.href = "/embed";
  }
}

if(formLogin){

  //captcha
  grecaptcha.ready(function() {
    getToken();
    });
  
    function getToken(){
    grecaptcha.execute('6Lc7z_kUAAAAAKUBuG-wrKHa71Ki-Aq12-x51haD', {action: 'homepage'}).then(function(token) {
          document.querySelector('#cap').value = token;
    });
  }
  
  //envia form login
  formLogin.addEventListener('submit', function(e){
    e.preventDefault();

    //campos
    var email = formLogin.querySelector('[name="email"]'),
    senha = formLogin.querySelector('[name="senha"]'),
    cap = formLogin.querySelector('#cap');

    //dados
    var data = {
      "email": email.value,
      "password": senha.value
    }

    //alert e loading
    containerAlert = formLogin.querySelector('.alertMsn');
    containerAlert.classList.remove('erro');
    containerAlert.innerHTML = '<img style="height:40px" src="https://sjcc.ne10.uol.com.br/quiz-sjcc/img/loading3.svg"/>';

    //envia dados
    fetch(`${window.location.origin}/api/login`, {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json, text-plain, */*",
        "X-Requested-With": "XMLHttpRequest"
      },
      method: "POST",
      body: JSON.stringify(data)
    }).then((response) => response.json())
      .then(function (data) {
        if(data.message){
          containerAlert.classList.add('erro');
          containerAlert.innerHTML = data.message;
        }else{
          window.localStorage.setItem('userData', JSON.stringify(data));
          setTimeout(() => {
            location.href = "/embed";
          }, 100);
        }
      })
      .catch(function (error) {
        console.log('Error:', error);
      });

  });

}