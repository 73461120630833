
//form cadastro
var formCadastro = document.querySelector('#cadastro');

if(formCadastro){

  //captcha
  grecaptcha.ready(function() {
    getToken();
    });
  
    function getToken(){
    grecaptcha.execute('6Lc7z_kUAAAAAKUBuG-wrKHa71Ki-Aq12-x51haD', {action: 'homepage'}).then(function(token) {
          document.querySelector('#cap').value = token;
    });
  }

  //termo

  var termo = formCadastro.querySelector('[name="termo"]');

  termo.addEventListener('change', function(e){
    e.preventDefault();
    if(this.checked){
      this.closest('form').querySelector('button').disabled = false;
      this.value = 1 ;
    }else{
      this.closest('form').querySelector('button').disabled = true;
      this.value = 0;
    }
  }); 
  
  //submit form cadastro
  formCadastro.addEventListener('submit', function(e){
    e.preventDefault();

      //campos
      var nome = formCadastro.querySelector('[name="nome"]'),
      email = formCadastro.querySelector('[name="email"]'),
      senha = formCadastro.querySelector('[name="senha"]'),
      confirmaSenha = formCadastro.querySelector('[name="confirmasenha"]'),
      cap = formCadastro.querySelector('#cap');
     
      //dados
      var dataCadastro = {
        "nome": nome.value,
        "email": email.value,
        "password": senha.value,
        "termo": termo.value
      }

      //alert loading
      containerAlert = formCadastro.querySelector('.alertMsn');
      containerAlert.classList.remove('erro');
      containerAlert.innerHTML = '<img style="height:40px" src="https://sjcc.ne10.uol.com.br/quiz-sjcc/img/loading3.svg"/>';

      //verifica senhas e envia
      if(senha.value == confirmaSenha.value){

       if(termo.value == '1'){
          fetch(`${window.location.origin}/api/cadastro`, {
            method: "POST",
            body: JSON.stringify(dataCadastro),
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json, text-plain, */*",
              "X-Requested-With": "XMLHttpRequest",
              }
            }).then((response) => response.json())
            .then(function (data) {
              if(data.message){
                containerAlert.classList.add('erro');
                containerAlert.innerHTML = data.message;
              }else{
                window.localStorage.setItem('userData', JSON.stringify(data));
                setTimeout(() => {
                  location.href = "/embed";
                }, 100);
              }
            })
            .catch(function (error) {
              console.log('Error:', error);
            });
       }else{
          containerAlert.classList.add('erro');
          containerAlert.innerHTML = "Você precisar marcar concordo para proseguir."
       }

      }else{
          containerAlert.classList.add('erro');
          containerAlert.innerHTML = "As senhas não coincidem"
      }
    

    
  });
}






